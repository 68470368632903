<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            max-width="600"
            width="600"
            class="pa-5"
          >
            <h3 class="text-h5">
              Subscription
            </h3>

            <v-progress-circular
              v-if="subscriptionDataStatus === 'loading'"
              indeterminate
              :size="64"
              class="d-block mx-auto"
            />

            <template v-else-if="subscriptionDataStatus === 'loaded'">
              <template v-if="subscriptionData.payment_method !== undefined">
                <v-alert
                  v-if="changesSaved"
                  type="success"
                >
                  Your change request was successfully made. Please allow a
                  few minutes for your change request to be processed.
                </v-alert>

                <template v-else>
                  <template v-if="subscriptionPlanPendingChange.change === 'canceled'">
                    <v-alert
                      v-if="reactivationFormErrors.non_field_errors"
                      dense
                      border="left"
                      type="warning"
                    >
                      {{ reactivationFormErrors.non_field_errors[0] }}
                    </v-alert>

                    <v-alert
                      dense
                      border="left"
                      type="warning"
                    >
                      Your subscription has been canceled. This action will be
                      finalized on
                      {{ new Date(
                        subscriptionPlanPendingChange.datetime
                      ).toLocaleString('en-US') }},
                      at which point your saved searches and downloaded files
                      will be deleted.
                    </v-alert>

                    <v-btn
                      type="submit"
                      color="primary"
                      :disabled="reactivationFormSubmitting"
                      class="mr-2"
                      @click="submitReactivationForm"
                    >
                      Reactivate Subscription
                      <v-progress-circular
                        v-if="reactivationFormSubmitting"
                        size="20"
                        width="2"
                        indeterminate
                        class="ml-2"
                      />
                    </v-btn>
                  </template>

                  <template v-else>
                    <div class="mb-8">
                      <h4>Payment Method</h4>

                      <div>
                        {{ subscriptionData.payment_method.card_type }} ending
                        with {{ subscriptionData.payment_method.last_four }}
                      </div>

                      <p>
                        Expiration:
                        {{ subscriptionData.payment_method.exp_month }} /
                        {{ subscriptionData.payment_method.exp_year }}
                      </p>

                      <v-btn
                        :href="`https://unlimitedmailinglists.recurly.com/account/billing_info/edit?ht=${subscriptionData.recurly_hosted_login_token}`"
                        target="_blank"
                        x-small
                      >
                        Change
                      </v-btn>
                    </div>

                    <v-alert
                      v-if="newSubscriptionPlanFormErrors.non_field_errors"
                      dense
                      border="left"
                      type="warning"
                    >
                      {{ newSubscriptionPlanFormErrors.non_field_errors[0] }}
                    </v-alert>

                    <v-alert
                      v-else-if="subscriptionPlanChangeFormErrors.non_field_errors"
                      dense
                      border="left"
                      type="warning"
                    >
                      {{ subscriptionPlanChangeFormErrors.non_field_errors[0] }}
                    </v-alert>

                    <v-alert
                      v-else-if="cancelPendingChangeFormErrors.non_field_errors"
                      dense
                      border="left"
                      type="warning"
                    >
                      {{ cancelPendingChangeFormErrors.non_field_errors[0] }}
                    </v-alert>

                    <template v-if="subscriptionPlanPendingChange.change !== ''">
                      <v-alert
                        dense
                        border="left"
                        type="info"
                      >
                        Your subscription plan has been changed from
                        {{ getPlanName(subscriptionData.active_subscription_plan) }}
                        to
                        {{ getPlanName(subscriptionPlanPendingChange.change) }}.
                        This will take effect on
                        {{ new Date(
                          subscriptionPlanPendingChange.datetime,
                        ).toLocaleString('en-US') }}.
                      </v-alert>

                      <v-btn
                        color="primary"
                        :disabled="cancelPendingChangeFormSubmitting"
                        @click="submitCancelPendingChangeForm"
                      >
                        Cancel This Change
                        <v-progress-circular
                          v-if="cancelPendingChangeFormSubmitting"
                          size="20"
                          width="2"
                          indeterminate
                          class="ml-2"
                        />
                      </v-btn>
                    </template>

                    <template v-else>
                      <v-alert
                        v-if="subscriptionData.subscription_status.startsWith('inactive')"
                        dense
                        border="left"
                        type="info"
                      >
                        Your subscription

                        <template
                          v-if="subscriptionData.subscription_status === 'inactive_failed_payment'"
                        >
                          is inactive due to lack of payment.
                        </template>

                        <template
                          v-else-if="subscriptionData.subscription_status === 'inactive_expired'"
                        >
                          has expired.
                        </template>
                      </v-alert>

                      <v-item-group
                        v-model="selectedSubscriptionPlan"
                        class="mb-3"
                      >
                        <v-row>
                          <v-col
                            v-for="subscriptionPlan of subscriptionData.subscription_plans"
                            :key="`subscriptionPlan${subscriptionPlan.code}`"
                            cols="12"
                            md="6"
                          >
                            <v-item v-slot="{ active, toggle }">
                              <button
                                type="button"
                                class="subscription-plan rounded-lg elevation-2 pa-3"
                                :class="{
                                  'primary': active,
                                  'subscription-plan-selected': active,
                                }"
                                @click="toggle"
                              >
                                <div class="subscription-plan-name">
                                  {{ subscriptionPlan.name }}
                                </div>

                                <div class="subscription-plan-price mb-3">
                                  ${{ formatCurrency(subscriptionPlan.price_per_month) }}
                                  / month
                                </div>

                                {{ formatNumber(subscriptionPlan.max_records_per_download) }}
                                records per download<br>
                                Unlimited downloads
                              </button>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-item-group>

                      <v-btn
                        v-if="subscriptionData.subscription_status === 'inactive_expired'"
                        color="primary"
                        :disabled="
                          newSubscriptionPlanFormSubmitting
                            || selectedSubscriptionPlan === undefined
                        "
                        @click="submitNewSubscriptionPlanForm"
                      >
                        Create a New Subscription with This Plan
                        <v-progress-circular
                          v-if="newSubscriptionPlanFormSubmitting"
                          size="20"
                          width="2"
                          indeterminate
                          class="ml-2"
                        />
                      </v-btn>

                      <v-btn
                        v-else
                        color="primary"
                        :disabled="
                          subscriptionPlanChangeFormSubmitting
                            || putOnHoldFormSubmitting
                            || cancellationFormSubmitting
                            || originalSubscriptionPlan === selectedSubscriptionPlan
                            || selectedSubscriptionPlan === undefined
                        "
                        @click="submitSubscriptionPlanChangeForm"
                      >
                        Save Plan Change
                        <v-progress-circular
                          v-if="subscriptionPlanChangeFormSubmitting"
                          size="20"
                          width="2"
                          indeterminate
                          class="ml-2"
                        />
                      </v-btn>

                      <div class="mt-8 text-center">
                        <a
                          href="https://verify.authorize.net/anetseal/?pid=8a79402b-9ffe-4098-9505-cfc311301f9f&rurl=https://www.unlimitedmailinglists.com"
                          target="_blank"
                        >
                          <img
                            src="@/assets/authorize.net-seal.png"
                            alt="Authorize.Net Verified Merchant"
                          >
                        </a>
                      </div>

                      <div
                        v-if="subscriptionData.subscription_status !== 'inactive_expired'"
                        class="mt-8"
                      >
                        <template v-if="putOnHoldOrCancel === null">
                          <v-btn
                            key="putSubscriptionOnHoldButton"
                            small
                            :disabled="subscriptionPlanChangeFormSubmitting"
                            class="mr-2"
                            @click="putOnHoldOrCancel = 'standby'"
                          >
                            Put Subscription on Hold
                          </v-btn>

                          <v-btn
                            key="cancelSubscriptionButton"
                            small
                            :disabled="subscriptionPlanChangeFormSubmitting"
                            @click="putOnHoldOrCancel = 'cancel'"
                          >
                            Cancel Subscription
                          </v-btn>
                        </template>

                        <template v-else-if="putOnHoldOrCancel === 'standby'">
                          <v-alert
                            v-if="putOnHoldFormErrors.non_field_errors"
                            dense
                            border="left"
                            type="warning"
                          >
                            {{ putOnHoldFormErrors.non_field_errors[0] }}
                          </v-alert>

                          <v-alert type="info">
                            Putting your subscription on hold costs only
                            ${{ formatCurrency(subscriptionData.standby_plan.price_per_month) }}
                            / month and allows you to keep your existing saved
                            searches and downloaded files.
                          </v-alert>

                          <v-btn
                            type="submit"
                            color="primary"
                            :disabled="
                              subscriptionPlanChangeFormSubmitting || putOnHoldFormSubmitting
                            "
                            class="mr-2"
                            @click="submitPutOnHoldForm"
                          >
                            Put Subscription on Hold
                            (${{ formatCurrency(subscriptionData.standby_plan.price_per_month) }}
                            / month)
                            <v-progress-circular
                              v-if="putOnHoldFormSubmitting"
                              size="20"
                              width="2"
                              indeterminate
                              class="ml-2"
                            />
                          </v-btn>

                          <v-btn
                            :disabled="
                              subscriptionPlanChangeFormSubmitting || putOnHoldFormSubmitting
                            "
                            @click="putOnHoldOrCancel = null"
                          >
                            Go Back
                          </v-btn>
                        </template>

                        <template v-else>
                          <v-alert
                            v-if="cancellationFormErrors.non_field_errors"
                            dense
                            border="left"
                            type="warning"
                          >
                            {{ cancellationFormErrors.non_field_errors[0] }}
                          </v-alert>

                          <v-alert type="warning">
                            If you cancel your subscription, your saved
                            searches and downloaded files will be deleted.
                          </v-alert>

                          <v-btn
                            type="submit"
                            color="primary"
                            :disabled="
                              subscriptionPlanChangeFormSubmitting || cancellationFormSubmitting
                            "
                            class="mr-2"
                            @click="submitCancellationForm"
                          >
                            Cancel Subscription
                            <v-progress-circular
                              v-if="cancellationFormSubmitting"
                              size="20"
                              width="2"
                              indeterminate
                              class="ml-2"
                            />
                          </v-btn>

                          <v-btn
                            :disabled="
                              subscriptionPlanChangeFormSubmitting || cancellationFormSubmitting
                            "
                            @click="putOnHoldOrCancel = null"
                          >
                            Go Back
                          </v-btn>
                        </template>
                      </div>
                    </template>
                  </template>
                </template>
              </template>

              <template v-else>
                You do not have a subscription.
              </template>
            </template>

            <template v-else-if="subscriptionDataStatus === 'error'">
              <v-alert
                dense
                border="left"
                type="error"
              >
                Unable to load your subscription data due to a communication
                error. Please check your connection and try again.
              </v-alert>

              <div class="text-right">
                <v-btn @click="loadSubscriptionData">
                  Try Again
                </v-btn>
              </div>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subscriptionDataStatus: 'loading',
      subscriptionData: {},
      originalSubscriptionPlan: undefined,
      selectedSubscriptionPlan: undefined,
      subscriptionPlanPendingChange: {
        datetime: null,
        change: '',
      },
      cancelPendingChangeFormErrors: {},
      cancelPendingChangeFormSubmitting: false,
      newSubscriptionPlanFormErrors: {},
      newSubscriptionPlanFormSubmitting: false,
      subscriptionPlanChangeFormErrors: {},
      subscriptionPlanChangeFormSubmitting: false,
      putOnHoldOrCancel: null,
      putOnHoldFormErrors: {},
      putOnHoldFormSubmitting: false,
      cancellationFormErrors: {},
      cancellationFormSubmitting: false,
      reactivationFormErrors: {},
      reactivationFormSubmitting: false,
      changesSaved: false,
    };
  },
  watch: {
    cancelPendingChangeFormErrors() {
      if (!this.isObjectEmpty(this.cancelPendingChangeFormErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
    newSubscriptionPlanFormErrors() {
      if (!this.isObjectEmpty(this.newSubscriptionPlanFormErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
    subscriptionPlanChangeFormErrors() {
      if (!this.isObjectEmpty(this.subscriptionPlanChangeFormErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
    putOnHoldFormErrors() {
      if (!this.isObjectEmpty(this.putOnHoldFormErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
    cancellationFormErrors() {
      if (!this.isObjectEmpty(this.cancellationFormErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
    reactivationFormErrors() {
      if (!this.isObjectEmpty(this.reactivationFormErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  metaInfo: {
    title: 'Subscription',
  },
  created() {
    this.loadSubscriptionData();
  },
  methods: {
    getPlanName(code) {
      const subscriptionPlan = this.subscriptionData.subscription_plans.find(
        (s) => s.code === code,
      );

      if (subscriptionPlan !== undefined) {
        return subscriptionPlan.name;
      }

      if (code === this.subscriptionData.standby_plan.code) {
        return this.subscriptionData.standby_plan.name;
      }

      return code;
    },
    async loadSubscriptionData() {
      this.subscriptionDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/subscription_data/',
      });

      if (responseData.status === 200) {
        this.subscriptionData = responseData.body;

        if (this.subscriptionData.payment_method !== undefined) {
          const subscriptionPlanPendingChange = responseData.body.subscription_plan_pending_change;

          if (subscriptionPlanPendingChange !== '') {
            this.subscriptionPlanPendingChange.datetime = subscriptionPlanPendingChange.slice(
              0,
              subscriptionPlanPendingChange.indexOf('|'),
            );

            this.subscriptionPlanPendingChange.change = subscriptionPlanPendingChange.slice(
              subscriptionPlanPendingChange.indexOf('|') + 1,
            );
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const [i, subscriptionPlan] of this.subscriptionData.subscription_plans.entries()) {
            if (subscriptionPlan.code === this.subscriptionData.active_subscription_plan) {
              this.originalSubscriptionPlan = i;
              this.selectedSubscriptionPlan = i;
              break;
            }
          }
        }

        this.subscriptionDataStatus = 'loaded';
      } else {
        this.subscriptionDataStatus = 'error';
      }
    },
    async submitCancelPendingChangeForm() {
      this.cancelPendingChangeFormErrors = {};
      this.cancelPendingChangeFormSubmitting = true;

      const responseData = await this.api({
        url: 'users/cancel_subscription_plan_pending_change/',
        method: 'POST',
      });

      this.cancelPendingChangeFormSubmitting = false;

      if (responseData.status === 200) {
        this.changesSaved = true;
      } else if (responseData.status === 400) {
        this.cancelPendingChangeFormErrors = responseData.body;
      } else {
        this.cancelPendingChangeFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    async submitNewSubscriptionPlanForm() {
      this.newSubscriptionPlanFormErrors = {};
      this.newSubscriptionPlanFormSubmitting = true;

      const responseData = await this.api({
        url: 'users/new_subscription/',
        method: 'POST',
        json: {
          new_subscription_plan: this.subscriptionData.subscription_plans[
            this.selectedSubscriptionPlan
          ].code,
        },
      });

      this.newSubscriptionPlanFormSubmitting = false;

      if (responseData.status === 200) {
        this.changesSaved = true;
      } else if (responseData.status === 400) {
        this.newSubscriptionPlanFormErrors = responseData.body;
      } else {
        this.newSubscriptionPlanFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    async submitSubscriptionPlanChangeForm() {
      this.subscriptionPlanChangeFormErrors = {};
      this.subscriptionPlanChangeFormSubmitting = true;

      const responseData = await this.api({
        url: 'users/change_subscription_plan/',
        method: 'POST',
        json: {
          new_subscription_plan: this.subscriptionData.subscription_plans[
            this.selectedSubscriptionPlan
          ].code,
        },
      });

      this.subscriptionPlanChangeFormSubmitting = false;

      if (responseData.status === 200) {
        this.changesSaved = true;
      } else if (responseData.status === 400) {
        this.subscriptionPlanChangeFormErrors = responseData.body;
      } else {
        this.subscriptionPlanChangeFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    async submitPutOnHoldForm() {
      this.putOnHoldFormErrors = {};
      this.putOnHoldFormSubmitting = true;

      const responseData = await this.api({
        url: 'users/put_subscription_on_hold/',
        method: 'POST',
      });

      this.putOnHoldFormSubmitting = false;

      if (responseData.status === 200) {
        this.changesSaved = true;
      } else if (responseData.status === 400) {
        this.putOnHoldFormErrors = responseData.body;
      } else {
        this.putOnHoldFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    async submitCancellationForm() {
      this.cancellationFormErrors = {};
      this.cancellationFormSubmitting = true;

      const responseData = await this.api({
        url: 'users/cancel_subscription/',
        method: 'POST',
      });

      this.cancellationFormSubmitting = false;

      if (responseData.status === 200) {
        this.changesSaved = true;
      } else if (responseData.status === 400) {
        this.cancellationFormErrors = responseData.body;
      } else {
        this.cancellationFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    async submitReactivationForm() {
      this.reactivationFormErrors = {};
      this.reactivationFormSubmitting = true;

      const responseData = await this.api({
        url: 'users/reactivate_subscription/',
        method: 'POST',
      });

      this.reactivationFormSubmitting = false;

      if (responseData.status === 200) {
        this.changesSaved = true;
      } else if (responseData.status === 400) {
        this.reactivationFormErrors = responseData.body;
      } else {
        this.reactivationFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>
